<template>
	<div class="home">
		user
		{{user}}
	</div>
</template>

<script>
	// @ is an alias to /src

	export default {
		name: 'Home',
		components: {},
		data() {
			return {
				user: 'hello world'
			};
		}
	}
</script>
